import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import { GenericPageLayout } from '../components/layouts'

class GenericPageTemplate extends React.Component {
    render() {
        const site = get(this.props, 'data.site.siteMetadata')
        const page = get(this.props, 'data.contentfulPageGenericPtp')
        let sitemap = get(this.props, 'data.allContentfulWidgetSitemapPtp.edges', [])
        sitemap = sitemap.map((link) => link && link.node)

        return <GenericPageLayout className="ptp-root" site={site} page={page} hasGroup={!!page.groups} location={this.props.location} sitemap={sitemap} />
    }
}

export default GenericPageTemplate

export const pageQuery = graphql`
    query GenericPagePTPBySlug($slug: String!) {
        site {
            siteMetadata {
                title
                description
                siteUrl
                twitterHandle
            }
        }
        contentfulPageGenericPtp(slug: { eq: $slug }) {
            __typename
            title
            slug
            userSection
            summary {
                summary
            }
            groups {
                __typename
                slug
                title
                contentful_id
                userSection
            }
            richText {
                childContentfulRichText {
                    internal {
                        content
                    }
                    html
                }
            }
            heroImage {
                title
                file {
                    url
                    details {
                        size
                    }
                }
            }
            heroVideo
            heroImageFullWidth
            showSocialShare
            metaDescription {
                metaDescription
            }
        }
        allContentfulWidgetSitemapPtp(filter: { title: { ne: "Don't delete - sitemap" } }) {
            edges {
                node {
                    contentful_id
                    userSection
                    title
                    slug
                    links {
                        __typename
                        ... on ContentfulPageDirectoryOfServicesPtp {
                            id
                            title
                        }
                        ... on ContentfulPageGenericPtp {
                            slug
                            title
                            userSection
                        }
                        ... on ContentfulPageGroupPtp {
                            slug
                            title
                            userSection
                        }
                        ... on ContentfulPageWidgetsPtp {
                            title
                            slug
                            userSection
                        }
                    }
                }
            }
        }
    }
`
